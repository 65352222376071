import { css } from '@emotion/core'
import React from 'react'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  innerHTML: string
}

export const Markdown: React.FC<Props> = ({ innerHTML, ...restProps }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: innerHTML }}
      css={css`
        a {
          color: var(--color-accent);
          text-decoration: underline;

          :hover {
            text-decoration: none;
          }
        }

        h2,
        h3 {
          margin: 0.5em 0 0.2em;
          line-height: 1.15;
        }

        h2 {
          font-size: 1.5em;
        }

        h3 {
          font-size: 1.25em;
        }

        p,
        blockquote,
        dl,
        ol,
        ul {
          &:not(:last-of-type) {
            margin-bottom: 1.5em;
          }
        }

        hr {
          margin-top: 3em;
          margin-bottom: 3em;
          border-color: var(--color-gray-300);
        }

        blockquote {
          padding: 0 1.5em;
          font-style: italic;
        }

        img {
          border-radius: var(--rounded);
          box-shadow: var(--shadow-lg);
        }

        img + em {
          display: block;
          margin-top: 0.5em;
          text-align: center;
        }

        ol {
          list-style-type: decimal;
        }

        ul {
          list-style-type: initial;
        }

        ol,
        ul {
          padding-left: 2rem;
        }

        li {
          margin: 0.5em 0;
          padding-left: 0.3em;
        }
      `}
      {...restProps}
    />
  )
}
