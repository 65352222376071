import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import React from 'react'
import { BrandOverview } from '../components/BrandOverview'
import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb'
import { Markdown } from '../components/Markdown'
import { PageH1 } from '../components/PageH1'
import { WidthWrapper } from '../components/WidthWrapper'
import { ProductsPageQuery } from '../types/graphql'
import { getBrandSubcategoryTree } from '../utils/dato'

type Props = {
  data: ProductsPageQuery
}

const ProductsPage: React.FC<Props> = ({ data }) => {
  const { seoMetaTags, content } = data.datoCmsProductsPage!

  return (
    <>
      <HelmetDatoCms seo={seoMetaTags} />

      <main>
        <Breadcrumb>
          <BreadcrumbItem>Products</BreadcrumbItem>
        </Breadcrumb>

        <PageH1>Products</PageH1>

        <WidthWrapper>
          <section className="max-w-3xl mx-auto flex flex-wrap justify-center p-4 bg-white rounded shadow-lg">
            {!content || !content.length
              ? 'No content'
              : content.map(topLevelCategory =>
                  !topLevelCategory ||
                  !topLevelCategory.slug ||
                  !topLevelCategory.categoryTitle ? (
                    'No top level categories'
                  ) : (
                    <>
                      {topLevelCategory.brands && topLevelCategory.brands.length
                        ? topLevelCategory.brands.map(brand => (
                            <a
                              href={'#' + brand!.slug}
                              className="p-4 lg:px-6 flex flex-col items-center"
                            >
                              <img
                                src={brand!.logo!.url!}
                                alt=""
                                className="w-16 h-8 object-contain"
                              />
                              <p className="mt-4 text-sm text-gray-600">
                                {brand!.name}
                              </p>
                            </a>
                          ))
                        : null}
                    </>
                  ),
                )}
          </section>

          <div>
            {!content || !content.length
              ? 'No content'
              : content.map(topLevelCategory =>
                  !topLevelCategory ||
                  !topLevelCategory.slug ||
                  !topLevelCategory.categoryTitle ? (
                    'No top level categories'
                  ) : (
                    <section
                      id={topLevelCategory.slug}
                      key={topLevelCategory.slug}
                      className="pt-16 pb-20 md:flex border-t border-gray-300 first:border-t-0"
                    >
                      <header className="md:w-1/2 pr-8">
                        <div className="md:sticky md:top-8 md:p-6 lg:p-8 md:border-l-2 md:border-accent-800">
                          <h2 className="text-2xl text-accent-800 leading-tight">
                            {topLevelCategory.categoryTitle}
                          </h2>

                          <div className="mt-4 max-w-sm mb-16 md:mb-0">
                            <Markdown
                              innerHTML={topLevelCategory.description!}
                            />
                          </div>
                        </div>
                      </header>

                      {!topLevelCategory.brands ||
                      !topLevelCategory.brands.length ? (
                        <div>No brands in this category</div>
                      ) : (
                        <div className="md:w-1/2">
                          {topLevelCategory.brands.map(brand =>
                            brand &&
                            brand.slug &&
                            brand.name &&
                            brand.business &&
                            brand.brandColor &&
                            brand.brandColor.hex &&
                            brand.logo &&
                            brand.logo.url &&
                            brand.brandSubcategoryEntry &&
                            brand.brandSubcategoryEntry.treeChildren &&
                            brand.brandSubcategoryEntry.treeChildren.length ? (
                              <div
                                key={brand.slug}
                                className="mt-16 first:mt-0"
                              >
                                <BrandOverview
                                  name={brand.name}
                                  slug={brand.slug}
                                  business={brand.business}
                                  brandColor={brand.brandColor.hex}
                                  logoUrl={brand.logo.url}
                                  subcategoryTree={getBrandSubcategoryTree(
                                    brand.brandSubcategoryEntry.treeChildren,
                                    brand.slug,
                                  )}
                                />
                              </div>
                            ) : null,
                          )}
                        </div>
                      )}
                    </section>
                  ),
                )}
          </div>
        </WidthWrapper>
      </main>
    </>
  )
}

export const query = graphql`
  query ProductsPageQuery {
    datoCmsProductsPage {
      seoMetaTags {
        tags
      }

      content {
        ... on DatoCmsTopLevelCategory {
          categoryTitle
          slug
          description
          brands {
            ...BrandOverviewFragment
          }
        }
      }
    }
  }

  fragment BrandOverviewFragment on DatoCmsBrand {
    name
    slug
    logo {
      url
    }
    business
    brandColor {
      hex
    }
    brandSubcategoryEntry {
      ...BrandSubcategoryTreeFragment
    }
  }
`

export default ProductsPage
