import React from 'react'
import { cx } from '../utils/helpers'
import { WidthWrapper } from './WidthWrapper'

type Props = {
  subtitle?: React.ReactNode
}

export const PageH1: React.FC<Props> = ({ children, subtitle }) => {
  return (
    <WidthWrapper className="my-8">
      <div className="max-w-sm">
        <h1
          className={cx(
            'inline-block mb-2 pb-4 border-b-4 border-gray-300',
            'text-accent-800 text-2xl font-bold leading-tighter',
          )}
        >
          {children}
        </h1>
        {subtitle && <div className="text-xl text-gray-700">{subtitle}</div>}
      </div>
    </WidthWrapper>
  )
}
