import { css } from '@emotion/core'
import { Link } from 'gatsby'
import React, { useRef, useState } from 'react'
import { MenuItem } from '../types'
import { cx } from '../utils/helpers'
import { FeatherIcon } from './FeatherIcon'

type BrandSubcategoryTreeProps = {
  menuItems: MenuItem[]
  isRoot?: boolean
}

export const BrandSubcategoryTree: React.FC<BrandSubcategoryTreeProps> = ({
  menuItems,
  isRoot = false,
}) => {
  const ref = useRef<HTMLUListElement>(null)
  const role = isRoot ? 'tree' : 'group'

  return (
    <ul
      ref={ref}
      role={role}
      className={cx(
        'mt-2 pl-4 border-l border-gray-300',
        role === 'group' && 'mb-2',
      )}
      css={css`
        [role='treeitem'][aria-expanded='false'] > & {
          display: none;
        }
      `}
    >
      {menuItems.map(({ id: itemId, text, href, childItems }) => (
        <BrandSubcategoryTreeItem
          key={itemId}
          id={itemId}
          text={text}
          href={href}
          childItems={childItems}
        />
      ))}
    </ul>
  )
}

const BrandSubcategoryTreeItem: React.FC<MenuItem> = ({
  id,
  text,
  href,
  childItems,
}) => {
  const [isExpanded, setExpanded] = useState(false)
  const hasChildItems = childItems && childItems.length

  return (
    <li
      role={hasChildItems ? 'treeitem' : 'none'}
      aria-expanded={hasChildItems ? isExpanded : undefined}
    >
      <Link
        to={href}
        role={hasChildItems ? undefined : 'treeitem'}
        onClick={
          hasChildItems
            ? event => {
                event.preventDefault()
                setExpanded(!isExpanded)
              }
            : undefined
        }
        className="flex justify-between items-center py-2"
        css={css`
          :hover,
          &[aria-current='page'] {
            color: var(--color-accent);
          }
        `}
      >
        <span>{text}</span>
        {hasChildItems && (
          <span>
            <FeatherIcon
              name={isExpanded ? 'minus' : 'plus'}
              size={16}
              aria-label="Expand"
            />
          </span>
        )}
      </Link>

      {childItems && childItems.length && (
        <BrandSubcategoryTree
          menuItems={[
            {
              id: `overview-${id}`,
              text: 'Overview',
              href,
            },
            ...childItems,
          ]}
        />
      )}
    </li>
  )
}
