import { Link } from 'gatsby'
import React from 'react'
import { MenuItem } from '../types'
import { resolvePath } from '../utils/dato'
import { BrandSubcategoryTree } from './BrandSubcategoryTree'

type Props = {
  name: string
  slug: string
  business: string
  brandColor: string
  logoUrl: string
  subcategoryTree: MenuItem[]
}

export const BrandOverview: React.FC<Props> = ({
  name,
  slug,
  business,
  brandColor,
  logoUrl,
  subcategoryTree,
}) => {
  return (
    <article
      id={slug}
      style={{
        borderColor: brandColor,
      }}
      className="bg-white p-fluid-md rounded-lg shadow-xl border-t-2"
    >
      <header>
        <Link to={resolvePath({ kind: 'brand', slug })}>
          <img
            src={logoUrl}
            alt=""
            className="w-32 h-16 object-contain object-left"
          />

          <h3 className="mt-4 text-2xl font-bold">{name}</h3>

          <p className="mt-2 text-lg">{business}</p>
        </Link>
      </header>

      <section className="mt-8">
        <BrandSubcategoryTree isRoot={true} menuItems={subcategoryTree} />
      </section>
    </article>
  )
}
